import React from "react";
import logoImage from "../logo.svg";
import { Link } from "react-router-dom";

class Header extends React.Component {
  render() {
    const headerList = [
      {
        name: "会社概要",
        page: "/info",
      },
      {
        name: "事業案内",
        page: "/business",
      },
      {
        name: "お問い合わせ",
        page: "/contact",
      },
    ];

    return (
      <div>
        <header className="page-header wrapper">
          <h1>
            <Link to="/">
              <img
                className="logo"
                src={logoImage}
                alt="SESパートナーズ ホーム"
              />
            </Link>
          </h1>
          <nav>
            <ul className="main-nav">
              {headerList.map((headerItem, index) => {
                return (
                  <li key={index}>
                    <Link to={headerItem.page}>{headerItem.name}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
