import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export const Form = () => {
  const [resultMessage, setResultMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setResultMessage(
            "送信されました！お問い合わせいただきありがとうございます。弊社からの返信をお待ちください。"
          );
        },
        (error) => {
          console.log(error.text);
          setResultMessage("※送信に失敗しました");
        }
      );
  };

  return (
    <div>
      <form action="#" ref={form} onSubmit={sendEmail}>
        <div>
          <label>種別</label>
        </div>
        <div className="radio-buttons">
          <label htmlFor="corporation">
            <input
              id="corporation"
              type="radio"
              name="radio-classification"
              value="法人"
            ></input>
            法人
          </label>
          <label htmlFor="personal">
            <input
              id="personal"
              type="radio"
              name="radio-classification"
              value="個人"
            ></input>
            個人
          </label>
        </div>

        <div>
          <label htmlFor="company">御社名（法人の方のみご記入ください）</label>
          <input type="text" id="company" name="company-name"></input>
        </div>

        <div>
          <label htmlFor="name">お名前</label>
          <input type="text" id="name" name="name"></input>
        </div>

        <div>
          <label htmlFor="email">メールアドレス</label>
          <input type="email" id="email" name="email"></input>
        </div>

        <div>
          <label htmlFor="telephone">電話番号</label>
          <input type="text" id="telephone" name="telephone"></input>
        </div>

        <div>
          <label htmlFor="message">お問い合わせ内容</label>
          <textarea id="message" name="message"></textarea>
        </div>

        <input
          id="button-form-sending"
          type="submit"
          className="button"
          value="送信"
        ></input>
      </form>
      <div className="result-message">
        <p>{resultMessage}</p>
      </div>
    </div>
  );
};
