import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Info from "./components/Info";
import Business from "./components/Business";
import Contact from "./components/Contact";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/info" element={<Info />} />
          <Route path="/business" element={<Business />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<div>404　ページが見つかりません。</div>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
