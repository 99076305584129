import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import asupage from "./asupage.jpg";

class Business extends React.Component {
  render() {
    return (
      <div>
        <div id="business" className="big-bg">
          <Header></Header>
          <div className="business-content wrapper">
            <h2 className="page-title">Business</h2>
            <p>
              私たちは、企業の営業戦略や人材育成、アウトソーシングに関するコンサルティングを通じて、お客様のビジネス成功を強力にサポートします。
              <br />
              <br className="br /-sp" />
              経験豊富なコンサルタントが最適なソリューションを提供し、ビジネスの成長を加速します。
            </p>
          </div>
        </div>

        <div className="container-business">
          <div className="item-top items">
            <div className="item-title">
              企業の営業に関する
              <br />
              コンサルティング業務
            </div>
            <div className="item-detail">
              弊社は、営業力強化のための戦略策定や顧客開拓の支援を行います。
              <br />
              お客様のニーズに合わせた独自のアプローチで、売上アップを実現します。
            </div>
          </div>
          <div className="item-middle items">
            <div className="item-title">
              人材育成に関する
              <br />
              コンサルティング業務
            </div>
            <div className="item-detail">
              弊社は、人材育成のプロフェッショナルです。最新の教育方法を取り入れ、社員のスキルアップとやる気向上を実現します。
            </div>
          </div>
          <div className="item-bottom items">
            <div className="item-title">
              アウトソーシング事業の
              <br />
              受託及び請負
            </div>
            <div className="item-detail">
              弊社は、お客様の業務を一手に引き受けます。高品質かつ効率的なサービスでコスト削減に貢献します。
              <br />
              信頼できるパートナーとして、お客様のビジネスに貢献します。
            </div>
          </div>
        </div>
        <div className="clear"></div>

        <div className="business2">取引先企業様</div>
        <div className="wrapper grid">
          {/* 画像サイズは1000x656に指定 */}
          <div className="item-business2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.asupage.co.jp/ "
            >
              <img src={asupage} alt="" />
            </a>
            <div className="item-title2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.asupage.co.jp/"
              >
                株式会社アスページ様
              </a>
            </div>
            <div className="item-detail2">情報通信業</div>
          </div>
          {/* 以下はテンプレート */}
          {/* <div className="item-business2">
                <img src="images/other.jpg" alt="">
                <div className="item-title2">株式会社○○様</div>
                <div className="item-detail2">ソフトウェア開発</div>
            </div>
            <div className="item-business2">
                <img src="images/other.jpg" alt="">
                <div className="item-title2">株式会社○○様</div>
                <div className="item-detail2">ソフトウェア開発</div>
            </div>
            <div className="item-business2">
                <img src="images/other.jpg" alt="">
                <div className="item-title2">株式会社○○様</div>
                <div className="item-detail2">ソフトウェア開発</div>
            </div>
            </div> */}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Business;
