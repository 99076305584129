import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="wrapper">
          <p>
            <small>&copy; 2023 SESパートナーズ</small>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
