import React from "react";
import Header from "./Header";
import Footer from "./Footer";

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 700) {
      this.setState({
        animate: true,
      });
    }
  }

  render() {
    return (
      <div>
        <div id="info" className="big-bg">
          <Header></Header>
          <div className="wrapper">
            <h2 className="page-title">PROFILE</h2>
          </div>
        </div>

        <div id="info2" className="big-bg">
          <div className="info2-content wrapper">
            <h3 className="info2-title">会社概要</h3>
            <div className="container-info">
              <div className="item-left">社名</div>
              <div className="item-right">合同会社SESパートナーズ</div>
            </div>
            <div className="container-info">
              <div className="item-left">設立</div>
              <div className="item-right">2023年1月</div>
            </div>
            <div className="container-info">
              <div className="item-left">資本金</div>
              <div className="item-right">1,000,000円</div>
            </div>
            <div className="container-info">
              <div className="item-left">代表者</div>
              <div className="item-right">逸見　健一</div>
            </div>
            <div className="container-info">
              <div className="item-left">所在地</div>
              <div className="item-right">
                〒134-0084
                <br />
                東京都江戸川区
                <br />
                東葛西8-38-19-603
              </div>
            </div>
            <div className="container-info">
              <div className="item-left">事業内容</div>
              <div className="item-right">
                企業の営業に関するコンサルティング業務
                <br />
                人材育成に関するコンサルティング業務
                <br />
                アウトソーシング事業の受託及び請負
              </div>
            </div>
          </div>
        </div>

        <section id="location">
          <div className="wrapper">
            <div
              className={`location-info ${this.state.animate ? "animate" : ""}`}
            >
              <h3 className="sub-title">SESパートナーズ</h3>
              <p>
                代表
                <br />
                逸見 健一　HEMMI KENICHI
                <br />
                <br />
                所在地
                <br />
                〒134-0084
                <br />
                東京都江戸川区東葛西
                <br />
                8-38-19-603
                <br />
                <br />
                Phone
                <br />
                <a href="tel:03-5605-7071"> 03-5605-7071</a>
                <br />
                <br />
                Mail
                <br />
                <a href="mailto:hemmi@ses-partners.jp">
                  k.hemmi@ses-partners.jp
                </a>
                <br />
                <br />
                Web
                <br />
                <a href="https://www.ses-partners.jp">
                  https://www.ses-partners.jp
                </a>
              </p>
            </div>
            <div className="location-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.8639715549007!2d139.87547257430708!3d35.6557229313774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60187d61b172c84b%3A0xf49e994b9ce3920a!2z44CSMTM0LTAwODQg5p2x5Lqs6YO95rGf5oi45bed5Yy65p2x6JGb6KW_77yY5LiB55uu77yT77yY4oiS77yR77yZIOODqeOCpOOCquODs-OCuuOCrOODvOODh-ODs-iRm-ilvw!5e0!3m2!1sja!2sjp!4v1681366347115!5m2!1sja!2sjp"
                allowFullScreen
                title="sesp-map"
              ></iframe>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    );
  }
}

export default Info;
