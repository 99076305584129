import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Form } from "./Form";
import { CSSTransition } from "react-transition-group";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
  }

  handleClickFormCreate() {
    this.setState({ isModalOpen: true });
  }

  handleClickClose() {
    this.setState({ isModalOpen: false });
  }

  render() {
    return (
      <div>
        <div id="contact" className="big-bg">
          <Header></Header>
          <div className="wrapper">
            <h2 className="page-title">Contact</h2>
            <div className="contact-description">
              <h3>＜お問い合わせフォームの作成＞</h3>
              <h4>
                お問い合わせありがとうございます。
                <br />
                ご返信までに、３〜４営業日いただいております。
                <br />
                あらかじめご了承ください。
                <br />
                <br />
                ご記入いただいたお問合せ者情報につきましては、
                お問合せに回答するためのみに利用させていただきます。
                <br />
                <br />
                ※入力した情報はSSL暗号化通信によって安全に送信されます。
                <br />
                <br />
                ↓作成ページへ移ります↓
              </h4>
            </div>
            <button
              className="form-create"
              onClick={() => {
                this.handleClickFormCreate();
              }}
            >
              作成
            </button>
            <CSSTransition
              in={this.state.isModalOpen}
              timeout={300}
              classNames="modal"
              unmountOnExit
            >
              <div className="modal">
                <div className="modal-inner">
                  <div className="modal-header"></div>
                  <Form />
                  <div className="modal-close">
                    <input
                      type="submit"
                      id="modal-close-button"
                      className="button"
                      value="フォームを閉じる×"
                      onClick={() => {
                        this.handleClickClose();
                      }}
                    ></input>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Contact;
