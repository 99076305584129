import React from "react";
import Header from "./Header";
import Footer from "./Footer";

class Home extends React.Component {
  render() {
    return (
      <div className="App">
        <div id="home" className="big-bg">
          <Header></Header>
          <div className="home-content wrapper">
            <h2 className="page-title">
              In the middle of difficulty,
              <br />
              lies opportunity.
            </h2>
            <p>
              <br className="br-sp" />
              困難の中に、チャンスがある。
              <br className="br-sp" />
              -アルバート・アインシュタイン-
              <br />
              <br className="br-sp" />
              「成果にコミットする」
              <br className="br-sp" />
              営業コンサルティングなら
              <br className="br-sp" />
              当社にお任せください。
              <br />
              <br className="br-sp" />
              人材育成も承ります。
            </p>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Home;
